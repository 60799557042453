import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

import styles from './index.module.scss';
import siteLogo from 'Assets/svg/siteLogo.svg';
import sideImg from 'Assets/images/bipedge-white.jpg';
import { API_BASE_URL } from 'Utils/constants';

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false)

  const sendFormData = async payload => {
    try {
      setLoading(true);
      const { data } = await Axios({
        method: 'POST',
        data: payload,
        url: `${API_BASE_URL}/utility/mailingList`
      });

      setLoading(false);
      resetForm();
      setAlert(true)
      const showAlert = setTimeout(() => { setAlert(false) }, 4000);
      return () => clearTimeout(showAlert);
    } catch (error) {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setEmail('');
    setFullName('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = { email, fullName };
    sendFormData(data);
  };

  return (
    <main className={styles.mainSection}>
      <section className={styles.leftSection}>
        <div className={styles.logoImg}>
          <Link to='/'><img src={siteLogo} alt="logo" /></Link>
        </div>

        <p className={styles.sectionText}>
          <span>Empowering employees</span> <br />
          <span>to SUCCESSFULLY</span> <br />
          Transit from Paid <br />
          to <span>Self employment</span>
        </p>

        <div className={styles.comingSoonText}>
          <p>Coming Soon</p>
          <p>Stay updated, Subscribe Now</p>
        </div>

        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="fullName">
              <input
                type="text"
                name="fullName"
                id="fullName"
                placeholder="Full Name"
                required
                value={fullName}
                onChange={e => setFullName(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label htmlFor="email">
              <input
                type="email"
                name="fullName"
                id="email"
                placeholder="Email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </label>
          </div>
          {alert &&
          <p className={styles.alert}>
            Thanks for subscribing, we will get back to you as soon as we launch.
          </p>
          }
          <button
            type="submit"
            className={styles.subscribeButton}
            disabled={loading}
          >
            {loading && <FontAwesomeIcon icon={faCircleNotch} spin />} Subscribe
          </button>
        </form>
      </section>
      <section className={styles.rightSection}>
        <img src={sideImg} alt="bi logo split in half with green overlay" />
      </section>
    </main>
  );
};

// ComingSoon.propTypes = {};

export default ComingSoon;
